<template>
  <div class="modal modal_wrap">
    <div class="modal_popup modal_full">
      <div class="container modal_content">
        <apply-sets v-if="sets"></apply-sets>
        <apply-package v-if="package"></apply-package>
        <apply-color v-if="color"></apply-color>
        <apply-locations v-if="locations" :inSet="true"></apply-locations>
      </div>
      <button class="btn btn_transparent" @click.prevent="closeModal"><svg-icon name="solid/times" /> Cancel</button>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex';
  import {defineAsyncComponent} from "vue";
  const ApplySets = defineAsyncComponent(() => import('./ApplySets'));
  const ApplyPackage = defineAsyncComponent(() => import('./ApplyPackage'));
  const ApplyColor = defineAsyncComponent(() => import('./ApplyColor'));
  const ApplyLocations = defineAsyncComponent(() => import('../SelectLocation'));

  export default {
    name: "ApplySetInOrder",
    data() {
      return {
        sets: true,
        package: false,
        color: false,
        locations: false,
      }
    },

    components: {
      ApplyPackage,
      ApplySets,
      ApplyColor,
      ApplyLocations
    },

    computed: {
    },

    methods: {
      closeModal() {
        this.$store.commit('createdOrder/warningModal', true);
      }
    },

    created() {

    }
  }
</script>

<style scoped lang="scss">
  .btn_transparent {
    margin-bottom: 0;
  }
</style>
